import { createPinia } from 'pinia';
import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';

import App from './App.vue';
import router from './router';

import './assets/main.css';
import { createHead } from '@vueuse/head';

const app = createApp(App);
const head = createHead();

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ["localhost", /^https:\/\/sunnycord\.me\/api/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: import.meta.env.DEV ? 1.0 : 0.2,
    replaysSessionSampleRate: import.meta.env.DEV ? 1.0 : 0.1,
    replaysOnErrorSampleRate: 1.0,
});

app.use(createPinia());
app.use(router);
app.use(head);

app.mount('#app');
